<template>
  <div id="app" class="w-full h-full bg-gray flex flex-col align-middle justify-center">
    <transition name="fade" mode="out-in">
      <Login v-if="!authorised" key="login" />
      <Project key="project" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './components/Login.vue';
import Project from './components/Project.vue';

export default {
  name: 'App',
  components: {
    Login,
    Project,
  },
  computed: {
    ...mapGetters({
      authorised: 'clickup/isAuthorised',
    }),
  },
};
</script>

<style scoped>
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 500ms ease;
}
</style>
