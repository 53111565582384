<template>
  <div class="w-10/12 bg-white mx-auto shadow rounded-lg p-10 text-center md:w-3/4 lg:w-1/2">
    <img src="@/assets/organik-logo-RGB-horizontal.svg" alt="Organik Web" class="w-3/4 mx-auto">

    <h1 class="font-heading text-heading mt-8">Project Reviewer</h1>

    <p class="mt-4">Please provide your passcode to review the progress of your current project with Organik Web.</p>

    <input
      type="text"
      v-model="passcode"
      :disabled="authorising"
      class="field mt-8 text-center"
    >

    <button
      class="w-full mt-4"
      :disabled="passcode === '' || authorising"
      @click="authorise({ passcode })"
      v-text="(authorising) ? 'Finding project...' : 'Check my project'"
    >
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      passcode: '',
    };
  },
  computed: {
    ...mapGetters({
      authorising: 'clickup/isAuthorising',
    }),
  },
  methods: {
    ...mapActions({
      authorise: 'clickup/authorise',
    }),
  }
}
</script>
