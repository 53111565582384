export default {
  namespaced: true,
  state: () => ({
    authorised: false,
    authorising: false,
    passcode: '',
    project: null,
    error: null,
  }),
  getters: {
    isAuthorising: (state) => state.authorising,
    isAuthorised: (state) => state.authorised,
    project: (state) => state.project,
  },
  mutations: {
    setPasscode(state, data) {
      state.passcode = data.passcode;
    },
    doAuth(state) {
      state.project = null;
      state.authorised = false;
      state.authorising = true;

      fetch(`${process.env.VUE_APP_API_URL}/api/code`, {
        method: 'POST',
        body: JSON.stringify({
          code: state.passcode,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(
        (response) => {
          if (response.status === 404) {
            state.error = 'We were unable to find a project with your code.';
          } else if (!response.ok) {
            state.error = 'An error occurred while trying to query our internal system. Please try again later.';
          } else {
            response.json().then((data) => {
              state.project = data;
              state.authorised = true;
            });
          }
        },
        (error) => {
          console.log(error);
        },
      ).finally(() => {
        state.authorising = false;
      });
    },
  },
  actions: {
    authorise({ commit }, data) {
      commit('setPasscode', { passcode: data.passcode });
      commit('doAuth');
    },
  },
};
