<template>
  <div class="w-full h-full overflow-y-scroll md:overflow-y-auto bg-gray justify-start p-8 md:p-12 lg:p-24">
    <div class="md:grid md:grid-cols-2">
      <div>
        <h2 class="uppercase text-label font-heading">
          Your project
        </h2>
        <h3 class="text-project font-project mt-4" v-text="project.project"></h3>
      </div>
      <div class="mt-8 md:mt-0 md:text-right">
        <h2 class="uppercase text-label font-heading" v-if="!project.completed">
          Estimated completion date
        </h2>
        <h3 class="text-project font-project mt-4" v-if="!project.completed" v-html="completionDate"></h3>
      </div>
    </div>

    <div class="bg-gray-light rounded-lg my-12 p-8 md:my-20 md:py-12 md:px-20">
      <div class="md:grid md:grid-cols-2 md:items-center">
        <div>
          <p v-if="!project.completed">
            Your project is currently in active development at <strong>Organik Web</strong>. You can review the progress of each milestone below.
            Your project manager is as follows:
          </p>
          <p v-else>
            Your project with <strong>Organik Web</strong> has finished. Thank you for choosing
            <strong>Organik Web</strong> for your project development, and we hope to work with you again soon.
            <br><br>
            If you encounter any issues with your site, please feel free to contact your project manager:
          </p>
        </div>
        <div>
          <div class="sm:flex sm:flex-row mt-8 md:mt-0 md:justify-end sm:gap-6">
            <div class="text-center sm:text-right">
              <strong v-text="project.contact.username"></strong><br>
              <button @click="call" class="py-2 px-4 mr-2 mt-4">Call</button>
              <button @click="email" class="py-2 px-4 mt-4">Email</button>
            </div>
            <div class="mt-8 sm:mt-0 sm:text-left">
              <img :src="project.contact.profilePicture" v-if="project.contact.profilePicture" class="h-20 w-20 mx-auto rounded-full">
              <div v-else class="h-20 w-20 flex flex-col rounded-full mx-auto" :style="{ background: project.contact.color }">
                <div class="font-project text-project" v-text="project.contact.initials"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(milestone, i) in project.milestones" :key="i" class="w-full lg:w-10/12 lg:mx-auto">
      <div class="border-t border-grey-300 my-12" v-if="i !== 0"></div>

      <div class="md:grid md:grid-cols-2 md:items-center">
        <div>
          <span
            class="text-milestone font-milestone"
            v-text="milestone.name"
          >
          </span>
        </div>

        <div class="mt-4 md:mt-0 md:text-right">
          <span
            class="block text-milestone font-milestone uppercase"
            v-text="milestone.status.status"
            :style="{ color: milestone.status.color }"
          >
          </span>
          <span
            v-if="milestone.status.status !== 'done' && milestone.expectedDate"
            class="block mt-4 md:mt-2 text-grey-400"
            v-text="'Estimated completion date: ' + fullDate(milestone.expectedDate)"
          >
          </span>
        </div>
      </div>
    </div>

    <div class="h-20"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Project',
  computed: {
    ...mapGetters({
      project: 'clickup/project',
    }),
    completionDate() {
      if (this.project.completed) {
        return '';
      }

      return this.fullDate(this.project.expectedDate);
    },
  },
  methods: {
    call() {
      window.location.assign('tel:08%209361%209534');
    },
    email() {
      window.location.assign(`mailto:${this.project.contact.email}`);
    },
    fullDate(timestamp) {
      const date = new Date(timestamp);

      let day;
      switch (date.getDay()) {
        case 0:
          day = 'Sunday';
          break;
        case 1:
          day = 'Monday';
          break;
        case 2:
          day = 'Tuesday';
          break;
        case 3:
          day = 'Wednesday';
          break;
        case 4:
          day = 'Thursday';
          break;
        case 5:
          day = 'Friday';
          break;
        default:
          day = 'Saturday';
      }

      let month;
      switch (date.getMonth()) {
        case 0:
          month = 'January';
          break;
        case 1:
          month = 'February';
          break;
        case 2:
          month = 'March';
          break;
        case 3:
          month = 'April';
          break;
        case 4:
          month = 'May';
          break;
        case 5:
          month = 'June';
          break;
        case 6:
          month = 'July';
          break;
        case 7:
          month = 'August';
          break;
        case 8:
          month = 'September';
          break;
        case 9:
          month = 'October';
          break;
        case 10:
          month = 'November';
          break;
        default:
          month = 'December';
      }

      return `${day} ${date.getDate()} ${month}, ${date.getFullYear()}`;
    },
  },
};
</script>
